// TODO:
// - [ ] Unify current value and pending value? (rather than having a
//   value in the object, then one is the 'pending' sub object).

declare global {
    let API_URL: string;
    let WS_URL: string;
}

import '@babel/polyfill';

import {
    MutableRefObject,
    ReactNode,
    memo,
    createContext,
    useEffect,
    useState,
    useCallback,
    useContext,
    useMemo,
    useRef,
} from 'react';
import { createPortal, render } from 'react-dom';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { Provider, useSelector, useDispatch } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    useParams,
    useLocation,
    useHistory,
} from 'react-router-dom';
import classNames from 'classnames';
import { createSelector } from 'reselect';
import * as moment from 'moment-timezone';
import ReduxThunk from 'redux-thunk';

import './style.css';

import { getNext, getView } from './common/syncable';
import { reducer as journalReducer } from './common/state';
import { changeStarredMedia } from './common/actions';
import { JournalId, SectionId, MediaId, GeoInfo } from './common/types';
import { useConnection } from './common/connection';

import { reducer as loginReducer } from './login-logic/state';
import {
    login,
    loginSuccesful,
    terminateSession,
    restoreState,
    disconnect,
} from './login-logic/actions';
import { LoginStatus } from './login-logic/types';

import { State } from './state';
import { useClocks } from './utils';

moment.locale('fr');

// const internalError = (msg: string) => console.error(msg);

type Display = 'by-sections' | 'by-date';

type Timezone = {
    name: string;
    timezone: string;
    short: string;
};

const TIMEZONES: Array<Timezone> = [
    { name: 'France', timezone: 'Europe/Paris', short: 'FR' },
    { name: 'New York', timezone: 'America/New_York', short: 'NY' },
    { name: 'San Francisco', timezone: 'America/Los_Angeles', short: 'SF' },
    { name: 'Angleterre', timezone: 'Europe/London', short: 'GB' },
];

const TimezoneContext = createContext<Timezone>(TIMEZONES[0]);

type SectionSummary = {
    id: SectionId;
    media: Array<MediaId>;
};

type JournalContextType =
    | { sections: Array<SectionSummary> }
    | { items: Array<MediaId> };

const JournalContext = createContext<JournalContextType>({ sections: [] });

const defaultCall = (f: any) => f();

type Function = (...args: any[]) => any;

const userCall = <T extends Function>(
    target: undefined | T,
    call: (f: T) => any = defaultCall,
): void => {
    if (typeof target === 'function') {
        try {
            call(target);
        } catch (e) {
            console.error(e);
        }
    }
};

const starCountSelector = (id: string) => (state: State) => {
    const { clocks } = state.main;
    let { starCount, starred } = state.main.mediaInfo[id];
    const [changed, value] = getView(starred, clocks);
    if (changed) {
        starCount += value ? 1 : -1;
    }
    return starCount;
};

const Pending = () => <span>Pending</span>;

type StarCountProps = {
    starred: boolean;
    count: number;
    onChange?: (isSet: boolean) => void;
    pending?: boolean;
};

const Pin = () => <img width="21" height="21" src="/assets/pin3.svg" />;

const StarCount = (props: StarCountProps) => {
    const { starred, count, onChange, pending } = props;
    const handleChange = useCallback(() => {
        userCall(onChange, f => f(!starred));
    }, [onChange, starred]);
    return (
        <span className={classNames('StarCount', { pending, starred })}>
            <span className="icon" onClick={handleChange}>
                <img
                    width="16"
                    height="16"
                    src={
                        starred
                            ? '/assets/star-full.svg'
                            : '/assets/star-empty.svg'
                    }
                />
            </span>
            <span className="count">{count}</span>
        </span>
    );
};

const round_ll = (n: number) => Math.round(n * 1e5) / 1e5;

const makeGeoLink = (geo: null | GeoInfo) => {
    if (geo === null) return null;
    const lat = round_ll(geo.latitude);
    const lng = round_ll(geo.longitude);
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
};

const formatTimestamp = (ts: number, tz: Timezone) => {
    const date = moment(ts).tz(tz.timezone).format('ddd D MMM YYYY HH:mm');
    return `${date.toUpperCase()} (${tz.short})`;
};

type MediaProps = {
    id: MediaId;
    onShowMedia?: (id: MediaId) => any;
};

const Media = (props: MediaProps) => {
    const { id, onShowMedia } = props;
    const clocks = useSelector((state: State) => state.main.clocks);
    const author = useSelector(
        (state: State) => state.main.mediaInfo[id].author,
    );
    const timestamp = useSelector(
        (state: State) => state.main.mediaInfo[id].timestamp,
    );
    const caption = useSelector(
        (state: State) => state.main.mediaInfo[id].caption,
    );
    const [_captionPending, captionValue] = getNext(caption, clocks); // FIXME: move to selector?
    const starred = useSelector(
        (state: State) => state.main.mediaInfo[id].starred,
    );
    const [starredPending, starredValue] = getNext(starred, clocks); // FIXME: move to selector?
    const uri = useSelector(
        (state: State) => state.main.mediaInfo[id].thumbnailUri,
    );
    const geo = useSelector((state: State) => state.main.mediaInfo[id].geo);
    const starCount = useSelector(starCountSelector(id));
    const dispatch = useDispatch();
    const handleChange = useCallback(
        value => {
            dispatch(changeStarredMedia(id, value));
        },
        [id],
    );
    const tz = useContext(TimezoneContext);
    const ts = formatTimestamp(timestamp * 1e3, tz);
    const geoLink = makeGeoLink(geo);
    const handleShowMedia = useCallback(() => {
        userCall(onShowMedia, f => f(id));
    }, [id]);
    return (
        <div className="Media">
            <div className="thumbnail" onClick={handleShowMedia}>
                {uri !== null ? (
                    <img loading="lazy" src={uri} width="128" height="128" />
                ) : null}
            </div>
            <div className="info">
                {author !== null ? <p className="">de {author}</p> : null}
                <p className="timestamp">{ts}</p>
                <p className="caption">{captionValue}</p>
                <div className="actions">
                    {geoLink && (
                        <a
                            href={geoLink}
                            target="_blank"
                            title="Emplacement sur la carte"
                        >
                            <Pin />
                        </a>
                    )}
                    <StarCount
                        starred={starredValue}
                        count={starCount}
                        onChange={handleChange}
                        pending={starredPending}
                    />
                </div>
            </div>
        </div>
    );
};

const cmp = <T,>(a: T, b: T): number => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

type SectionProps = {
    id: SectionId;
    onShowMedia?: (id: MediaId) => any;
};

const Section = (props: SectionProps) => {
    const { id, onShowMedia } = props;
    const clocks = useSelector((state: State) => state.main.clocks);
    const title = useSelector((state: State) => state.main.sections[id].title);
    // FIXME: reselect!
    const media = useSelector((state: State) =>
        Object.values(state.main.mediaLink)
            .filter(d => getView(d.section, clocks)[1] == id)
            .map(d => d.id)
            .sort((a, b) =>
                cmp(
                    state.main.mediaInfo[a].timestamp,
                    state.main.mediaInfo[b].timestamp,
                ),
            ),
    );
    const pending = useSelector(
        (state: State) => state.main.sections[id].creation.pending,
    );
    const error = useSelector(
        (state: State) => state.main.sections[id].creation.error,
    );
    const [titleChanged, titleValue] = getNext(title, clocks);
    return (
        <div className="Section">
            <div className="header">
                {pending && <span>Creation pending</span>}
                {error && <p>{error} [Click to retry]</p>}
                <h2>
                    {titleValue}
                    {titleChanged && <Pending />}
                </h2>
            </div>
            <div className="items">
                {media.map((id: string) => (
                    <Media key={id} id={id} onShowMedia={onShowMedia} />
                ))}
            </div>
        </div>
    );
};

const SyncState = () => {
    const state = useSelector((state: State) => state.main.connectionStatus);
    return (
        <div className={classNames('ConnectionStatus', state.toLowerCase())}>
            <span title={`Link: ${state}`} />
        </div>
    );
};

const useAutoRemoval = (ref: any) => {
    useEffect(() => {
        const el = ref.current;
        if (el === null) return;
        let disabled = false;
        let timeout: any;
        const show = () => {
            el.classList.remove('hidden');
            clearTimeout(timeout);
            if (!disabled) {
                timeout = setTimeout(() => el.classList.add('hidden'), 2500);
            }
        };
        const disable = () => {
            disabled = true;
            show();
        };
        const enable = () => {
            disabled = false;
            show();
        };
        show();
        el.addEventListener('mouseenter', disable);
        el.addEventListener('mouseleave', enable);
        document.addEventListener('mousemove', show);
        return () => {
            el.removeEventListener('mouseenter', disable);
            el.removeEventListener('mouseleave', enable);
            document.removeEventListener('mousemove', show);
        };
    }, [ref.current]);
};

type ViewerToolBarProps = {
    mapUrl?: null | string;
    onPreviousMedia?: () => any;
    onNextMedia?: () => any;
    downloadUrl?: null | string;
    onClose?: () => any;
    onStarToggle?: () => any;
    starred: boolean;
};

const ViewerToolBar = (props: ViewerToolBarProps) => {
    const stopEvent = useCallback((event: any) => {
        event.stopPropagation();
    }, []);
    const ref = useRef(null);
    useAutoRemoval(ref);
    return (
        <ul className="toolbar" onClick={stopEvent} ref={ref}>
            <li onClick={props.onStarToggle} title="Favorite">
                <img
                    src={
                        props.starred
                            ? '/assets/star-full.svg'
                            : '/assets/star-empty-white.svg'
                    }
                    width="28"
                    height="28"
                />
            </li>
            <li
                className={classNames({ disabled: props.downloadUrl == null })}
                title="Télécharger"
            >
                <a href={props.downloadUrl || undefined}>
                    <img src="/assets/download.svg" width="28" height="28" />
                </a>
            </li>
            <li
                className={classNames({ disabled: props.mapUrl == null })}
                title="Voir sur la carte"
            >
                <a href={props.mapUrl || undefined} target="_blank">
                    <img src="/assets/pin-white.svg" width="28" height="28" />
                </a>
            </li>
            <li onClick={props.onPreviousMedia} title="Précédent">
                <img src="/assets/left-arrow.svg" width="28" height="28" />
            </li>
            <li onClick={props.onNextMedia} title="Suivant">
                <img src="/assets/right-arrow.svg" width="28" height="28" />
            </li>
            <li onClick={props.onClose} title="Fermer">
                <img src="/assets/close.svg" width="28" height="28" />
            </li>
        </ul>
    );
};

type Dimensions = {
    width: number;
    height: number;
};

const useDimensions = (ref: MutableRefObject<null | HTMLElement>) => {
    const [dimensions, setDimensions] = useState<null | Dimensions>(null);
    useEffect(() => {
        let last: null | Dimensions = null;
        const intervalId = setInterval(() => {
            if (ref.current === null) {
                last = null;
                setDimensions(null);
            } else {
                const bb = ref.current.getBoundingClientRect();
                const d = {
                    width: bb.width,
                    height: bb.height,
                };
                if (
                    last === null ||
                    last.width !== d.width ||
                    last.height !== d.height
                ) {
                    last = d;
                    setDimensions(d);
                }
            }
        }, 100);
        return () => {
            clearInterval(intervalId);
        };
    }, [ref]);
    return dimensions;
};

type ViewerProps = {
    journal: JournalId;
    initialMedia: MediaId;
    onClose?: () => any;
};

// FIXME: Handle disappearing media
const Viewer = (props: ViewerProps) => {
    const dispatch = useDispatch();
    const [media, setMedia] = useState<MediaId>(props.initialMedia);
    const clocks = useClocks();
    const exists = useSelector((state: State) => media in state.main.mediaInfo);
    const info = useSelector((state: State) =>
        exists ? state.main.mediaInfo[media] : null,
    );
    const sectionId = useSelector((state: State) =>
        exists ? state.main.mediaLink[media].section.value : null,
    ); // FIXME: clocks?
    const section = useSelector((state: State) =>
        sectionId !== null ? state.main.sections[sectionId] : null,
    );
    const starred = info !== null ? getView(info.starred, clocks)[1] : false;
    const ref = useRef(null);
    const containerDimensions = useDimensions(ref);
    const [showInfo, setShowInfo] = useState(true);
    const geoLink = info !== null ? makeGeoLink(info.geo) : null;
    const tz = useContext(TimezoneContext);
    const caption = info !== null ? getView(info.caption, clocks)[1] : null;
    const handleToggleShowInfo = useCallback(
        (ev: any) => {
            ev.stopPropagation();
            ev.preventDefault();
            setShowInfo(!showInfo);
        },
        [setShowInfo, showInfo],
    );
    const handleStarToggle = useCallback(() => {
        dispatch(changeStarredMedia(media, !starred));
    }, [media, starred]);
    const journalContext = useContext(JournalContext);
    const allMedia = useMemo<Array<MediaId>>(() => {
        if ('sections' in journalContext) {
            return ([] as Array<MediaId>).concat(
                ...journalContext.sections.map(s => s.media),
            );
        } else {
            return journalContext.items;
        }
    }, [journalContext]);
    // TODO: Show position in section.
    const pos = allMedia.indexOf(media);
    const sectionPos = useMemo<null | { total: number; pos: number }>(() => {
        if ('sections' in journalContext) {
            for (let section of journalContext.sections) {
                const pos = section.media.indexOf(media);
                if (pos !== -1) return { total: section.media.length, pos };
            }
            return null;
        } else {
            const pos = journalContext.items.indexOf(media);
            if (pos !== -1) return { total: journalContext.items.length, pos };
            return null;
        }
    }, [journalContext, media]);
    const previousMedia =
        pos > 0 ? allMedia[pos - 1] : allMedia[allMedia.length - 1];
    const nextMedia =
        pos < allMedia.length - 1 ? allMedia[pos + 1] : allMedia[0];
    // const isFirst = pos === 0;
    // const isLast = pos === allMedia.length - 1;
    const handlePreviousMedia = useCallback(() => {
        if (previousMedia !== null) {
            setMedia(previousMedia);
        }
    }, [previousMedia]);
    const handleNextMedia = useCallback(() => {
        if (nextMedia !== null) {
            setMedia(nextMedia);
        }
    }, [nextMedia]);
    const shouldClose = !exists || pos === -1;
    useEffect(() => {
        if (shouldClose) {
            userCall(props.onClose);
        }
    }, [shouldClose]);
    const handleKeyPress = useCallback(
        (ev: any) => {
            let stop = true;
            if (ev.key === 'ArrowLeft') {
                handlePreviousMedia();
            } else if (ev.key === 'ArrowRight') {
                handleNextMedia();
            } else if (ev.key === ' ' || ev.key === 'Enter') {
                setShowInfo(!showInfo);
            } else {
                stop = false;
                // console.log('Key', ev.key);
            }
            if (stop) {
                ev.stopPropagation();
                ev.preventDefault();
            }
        },
        [handlePreviousMedia, handleNextMedia, showInfo],
    );
    const handleWheel = useCallback(
        (ev: any) => {
            if (ev.deltaY > 0) {
                handleNextMedia();
            } else if (ev.deltaY < 0) {
                handlePreviousMedia();
            } else {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();
        },
        [handlePreviousMedia, handleNextMedia],
    );
    useDocumentEvent('keydown', handleKeyPress);
    const sectionTitle = section !== null ? section.title.value : null;
    let width, height;
    if (
        containerDimensions !== null &&
        info !== null &&
        info.dimensions !== null
    ) {
        const { width: cw, height: ch } = containerDimensions;
        const { width: mw, height: mh } = info.dimensions;
        if (cw * mh < ch * mw) {
            width = cw;
            height = (cw * mh) / mw;
        } else {
            width = (ch * mw) / mh;
            height = ch;
        }
    }
    const ts = info !== null ? formatTimestamp(info.timestamp * 1e3, tz) : null;
    return (
        <div
            className={classNames('Viewer', { presentation: !showInfo })}
            ref={ref}
            onClick={props.onClose}
            onWheel={handleWheel}
        >
            {info !== null &&
            info.uri !== null &&
            width !== undefined &&
            height !== undefined ? (
                <div
                    className="zone"
                    style={{ width, height }}
                    onClick={handleToggleShowInfo}
                >
                    <img
                        key={info.uri}
                        src={info.uri}
                        style={{ width, height }}
                    />
                    {showInfo && (
                        <ul className="header">
                            <li>
                                {sectionTitle}
                                {sectionPos !== null
                                    ? ` (${sectionPos.pos + 1}/${
                                          sectionPos.total
                                      })`
                                    : null}
                            </li>
                            <li>{ts}</li>
                        </ul>
                    )}
                    {showInfo && caption && (
                        <div className="caption">{caption}</div>
                    )}
                </div>
            ) : null}
            {showInfo && (
                <ViewerToolBar
                    starred={starred}
                    mapUrl={geoLink}
                    onClose={props.onClose}
                    onPreviousMedia={handlePreviousMedia}
                    onNextMedia={handleNextMedia}
                    onStarToggle={handleStarToggle}
                    downloadUrl={info?.uri}
                />
            )}
        </div>
    );
};

const useDocumentEvent = (name: string, callback?: (event: any) => any) => {
    useEffect(() => {
        if (typeof callback !== 'function') return;
        document.addEventListener(name, callback);
        return () => {
            document.removeEventListener(name, callback);
        };
    }, [callback]);
};

const useEscapeTrigger = (callback?: () => any) => {
    const handleKeyPress = useCallback(
        ev => {
            if (ev.key === 'Escape') {
                userCall(callback);
            }
        },
        [callback],
    );
    useDocumentEvent('keydown', handleKeyPress);
};

type Overlay = {
    onEscapeKey?: () => any;
    children: any;
};

const Overlay = (props: Overlay) => {
    const el = document.getElementById('overlay');
    useEffect(() => {
        if (el === null) return;
        el.classList.add('isActive');
        return () => {
            el.classList.remove('isActive');
        };
    }, [el]);
    useEscapeTrigger(props.onEscapeKey);
    if (el == null) return null;
    return createPortal(props.children, el);
};

type TimezoneSelectorProps = {
    onChange?: (tz: Timezone) => any;
};

const TimezoneSelector = memo((props: TimezoneSelectorProps) => {
    const currentTimezone = useContext(TimezoneContext);
    const handleChange = (tz: Timezone) => {
        userCall(props.onChange, f => f(tz));
    };
    return (
        <div className="TimezoneSelector">
            Fuseau horaire:{' '}
            <ul className="choices">
                {TIMEZONES.map(tz => {
                    const current = currentTimezone === tz;
                    return (
                        <li
                            key={tz.timezone}
                            className={classNames({ current })}
                            onClick={() => handleChange(tz)}
                        >
                            {tz.name}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

type DisplaySelectorProps = {
    current: Display;
    onChange: (display: Display) => any;
};

const DisplaySelector = memo((props: DisplaySelectorProps) => {
    const CHOICES: Array<{ name: Display; title: string }> = [
        { name: 'by-sections', title: 'Par sections' },
        { name: 'by-date', title: "Par date d'ajout" },
    ];
    return (
        <div className="TimezoneSelector">
            Affichage:
            <ul className="choices">
                {CHOICES.map(({ name, title }) => {
                    const current = name === props.current;
                    return (
                        <li
                            key={name}
                            className={classNames({ current })}
                            onClick={() => props.onChange(name)}
                        >
                            {title}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

const ConnectedUser = () => {
    const dispatch = useDispatch();
    const username = useSelector((state: State) => state.login.user);
    const history = useHistory();
    const handleDisconnect = useCallback(() => {
        dispatch(terminateSession('Déconnecté'));
        history.push('/');
    }, []);
    return (
        <div className="ConnectedUser">
            {username}
            {' — '}
            <a onClick={handleDisconnect}>Se déconnecter</a>
        </div>
    );
};

type SectionsProps = {
    sections: Array<SectionSummary>;
    onShowMedia: (id: MediaId) => any;
};

const Sections = (props: SectionsProps) => {
    const { sections, onShowMedia } = props;
    return (
        <div className="sections">
            {sections.map(({ id }) => (
                <Section key={id} id={id} onShowMedia={onShowMedia} />
            ))}
        </div>
    );
};

const MediaList = (props: any) => {
    return (
        <div className="Section">
            <div className="header">
                <h2>{"Classement par date d'ajout"}</h2>
            </div>
            <div className="items">
                {props.items.map((id: string) => (
                    <Media key={id} id={id} onShowMedia={props.onShowMedia} />
                ))}
            </div>
        </div>
    );
};

type JournalViewProps = {
    id: JournalId;
};

const JournalView = memo((props: JournalViewProps) => {
    const { id } = props;

    // const dispatch = useDispatch();

    const clocks = useSelector((state: State) => state.main.clocks);
    const title = useSelector((state: State) => state.main.journals[id].title);
    const subtitle = useSelector(
        (state: State) => state.main.journals[id].subtitle,
    );

    const sectionsSelector = useMemo(
        () =>
            createSelector(
                (state: State) => state.main.sections,
                (state: State) => state.main.mediaInfo,
                (state: State) => state.main.mediaLink,
                (sections, media, links) => {
                    const data: { [key: string]: { [key: string]: number } } =
                        {};
                    Object.values(links).forEach(link => {
                        const section = sections[link.section.value];
                        if (section.journal !== id) return;
                        if (!(section.id in data)) {
                            data[section.id] = {};
                        }
                        data[section.id][link.id] = media[link.id].timestamp;
                    });
                    return Object.entries(data)
                        .map(([id, media]) => ({
                            id,
                            timestamp: Object.values(media).reduce((a, b) =>
                                Math.max(a, b),
                            ),
                            media: Object.entries(media)
                                .sort((a, b) => cmp(a[1], b[1]))
                                .map(([id, _timestamp]) => id),
                        }))
                        .sort((a, b) => -cmp(a.timestamp, b.timestamp))
                        .map(({ id, media }) => ({ id, media }));
                },
            ),
        [id],
    );

    const chronologicalSelector = useMemo(
        () =>
            createSelector(
                (state: State) => state.main.sections,
                (state: State) => state.main.mediaInfo,
                (state: State) => state.main.mediaLink,
                (sections, media, links) => {
                    const data: { [key: string]: number } = {};
                    Object.values(links).forEach(link => {
                        const section = sections[link.section.value];
                        if (section.journal !== id) return;
                        data[link.id] =
                            media[link.id].timestamp_added ??
                            media[link.id].timestamp;
                    });
                    return Object.entries(data)
                        .sort((a, b) => -cmp(a[1], b[1]))
                        .map(([id, _timestamp]) => id);
                },
            ),
        [id],
    );

    const sections = useSelector(sectionsSelector);
    const items = useSelector(chronologicalSelector);

    const [titleChanged, titleValue] = getNext(title, clocks);
    const [subtitleChanged, subtitleValue] = getNext(subtitle, clocks);
    const [mediaShown, setMediaShown] = useState<null | MediaId>(null);
    const viewer =
        mediaShown !== null ? (
            <Overlay onEscapeKey={() => setMediaShown(null)}>
                <Viewer
                    journal={id}
                    initialMedia={mediaShown}
                    onClose={() => setMediaShown(null)}
                />
            </Overlay>
        ) : null;
    const handleShowMedia = (id: MediaId) => {
        setMediaShown(id);
    };
    const [displayTimezone, setDisplayTimezone] = useState(TIMEZONES[0]);
    const [display, setDisplay] = useState<Display>('by-sections');

    return (
        <JournalContext.Provider
            value={display === 'by-date' ? { items } : { sections }}
        >
            <TimezoneContext.Provider value={displayTimezone}>
                <div className="Journal">
                    <div className="header">
                        <h1>
                            {titleValue}
                            {titleChanged && <Pending />}
                        </h1>
                        {subtitleValue ? (
                            <h2>
                                {subtitleValue}
                                {subtitleChanged && <Pending />}
                            </h2>
                        ) : null}
                    </div>
                    <div className="settings">
                        <div className="BackToJournalList">
                            <Link to="/">{'<'} Journaux</Link>
                        </div>
                        <DisplaySelector
                            onChange={setDisplay}
                            current={display}
                        />
                        <TimezoneSelector onChange={setDisplayTimezone} />
                        <div style={{ flex: 1 }} />
                        <SyncState />
                        <ConnectedUser />
                    </div>
                    {display === 'by-date' ? (
                        <MediaList
                            items={items}
                            onShowMedia={handleShowMedia}
                        />
                    ) : (
                        <Sections
                            sections={sections}
                            onShowMedia={handleShowMedia}
                        />
                    )}
                    {viewer}
                </div>
            </TimezoneContext.Provider>
        </JournalContext.Provider>
    );
});

const Journal = () => {
    const { id } = useParams<{ id: string }>();
    const exists = useSelector(
        (state: State) => id != null && id in state.main.journals,
    );
    // const user = useSelector((state: State) => state.user);
    return id != null && exists ? <JournalView id={id} /> : null;
};

type JournalListItemProps = {
    id: string;
    title: ReactNode;
    subtitle: ReactNode;
};

const JournalListItem = (props: JournalListItemProps) => {
    const { title, subtitle } = props;
    return (
        <>
            <p className="title">{title}</p>
            <p className="subtitle">{subtitle}</p>
        </>
    );
};

const JournalList = () => {
    const clocks = useClocks();
    const journals = useSelector((state: State) => state.main.journals);
    return (
        <div className="Journals">
            <ul className="JournalList">
                {Object.entries(journals).map(([k, v]: any) => {
                    return (
                        <li key={k}>
                            <Link to={`/journal/${k}`}>
                                <JournalListItem
                                    id={k}
                                    title={getView(v.title, clocks)}
                                    subtitle={getView(v.subtitle, clocks)}
                                />
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const useRootClass = (name: string) => {
    useEffect(() => {
        document.documentElement.classList.add(name);
        return () => {
            document.documentElement.classList.remove(name);
        };
    }, []);
};

const Login = () => {
    const dispatch = useDispatch();
    useRootClass('login');
    const userInput: any = useRef();
    const passwordInput: any = useRef();
    const reason = useSelector((state: State) => state.login.reason);
    const pending = useSelector(
        (state: State) => state.login.status === LoginStatus.Connecting,
    );
    const handleSubmit = useCallback((event: any) => {
        event.preventDefault();
        if (userInput.current !== null && passwordInput.current != null) {
            const user = userInput.current.value;
            const password = passwordInput.current.value;
            dispatch(login(user, password));
        }
    }, []);
    const [canConnect, setCanConnect] = useState(false);
    const handleChange = useCallback((ev: any) => {
        setCanConnect(ev.nativeEvent.target.value != '');
    }, []);
    return (
        <div className="Login">
            <h1>Journal</h1>
            <p className="error">{reason}</p>
            <form method="post" onSubmit={handleSubmit}>
                <p>
                    <input
                        ref={userInput}
                        onChange={handleChange}
                        name="user"
                        type="text"
                        placeholder="Identifiant"
                    />
                </p>
                <p>
                    <input
                        ref={passwordInput}
                        name="password"
                        type="password"
                        placeholder="Mot de passe"
                    />
                </p>
                <p>
                    <button
                        className={pending ? 'inProgress' : ''}
                        disabled={!canConnect || pending}
                        name="submit"
                    >
                        <span>
                            {pending ? 'Connection...' : 'Se connecter'}
                        </span>
                    </button>
                </p>
            </form>
        </div>
    );
};

const LoginRequired = () => {
    const location = useLocation();
    useRootClass('login');
    return (
        <div className="LoginRequired">
            <p>Vous devez vous reconnecter pour accéder à cette page.</p>
            <p>
                <Link to={`/?from=${location.pathname}`}>Se connecter</Link>
            </p>
        </div>
    );
};

const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const data = localStorage.getItem('auth');
        if (data !== null) {
            const auth = JSON.parse(data);
            dispatch(restoreState(auth.user, auth.token));
        } else {
            dispatch(disconnect(''));
        }
    }, []);
    const loginStatus = useSelector((state: State) => state.login.status);
    const user = useSelector((state: State) => state.login.user);
    const password = useSelector((state: State) => state.login.password);
    const attempt = useRef(0);
    const params = useMemo(() => {
        if (!user) return null;
        return {
            user,
            password,
            attempt: attempt.current,
        };
    }, [user, password, attempt.current]);
    const location = useLocation();
    const history = useHistory();
    const onLoginSuccesful = useCallback(
        (token?: string) => {
            dispatch(loginSuccesful());
            if (params !== null && token !== undefined) {
                const auth = {
                    user: params.user,
                    token,
                };
                localStorage.setItem('auth', JSON.stringify(auth));
            }
            const s = new URLSearchParams(location.search.substr(1));
            const from = s.get('from');
            if (from != null) {
                history.push(from);
            }
        },
        [dispatch, params],
    );
    const onWrongCredentials = useCallback(() => {
        dispatch(terminateSession('Identifiant ou mot de passe incorrect'));
        ++attempt.current;
    }, [dispatch]);
    useConnection(params, {
        onLoginSuccesful,
        onWrongCredentials,
    });
    if (loginStatus === LoginStatus.Unconfigured) return null;
    else if (
        loginStatus === LoginStatus.Disconnected ||
        loginStatus === LoginStatus.Connecting
    ) {
        return (
            <Switch>
                <Route path="/" exact>
                    <Login />
                </Route>
                <Route path="/">
                    <LoginRequired />
                </Route>
            </Switch>
        );
    } else {
        return (
            <Switch>
                <Route path="/" exact>
                    <JournalList />
                </Route>
                <Route path="/journal/:id" exact>
                    <Journal />
                </Route>
            </Switch>
        );
    }
};

const init = () => {
    const reducer = combineReducers({
        login: loginReducer,
        main: journalReducer,
    });

    const store = createStore(
        reducer,
        compose(
            applyMiddleware(ReduxThunk),
            (window as any).__REDUX_DEVTOOLS_EXTENSION__
                ? (window as any).__REDUX_DEVTOOLS_EXTENSION__({
                      name: 'Store',
                  })
                : (f: any) => f,
        ),
    );

    const el = document.createElement('div');
    el.id = 'main';
    document.body.appendChild(el);

    const overlay = document.createElement('div');
    overlay.id = 'overlay';
    document.body.appendChild(overlay);

    const root = (
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    );
    render(root, el);
};

window.addEventListener('load', init);
