import { WebSocketStatus } from '@/common/types';

// TODO:
// - [ ] Change the server to answer ACK call
//
// Call are serialized for simpler design. Any call wait for a
// response before sending the next queued one.

// const event = makeEVent();
// a. await event.get();
// b. await event.get();
// c. await event.get();
// d. event.trigger();
const makeEvent = () => {
    let promise: null | Promise<undefined> = null;
    let resolver: null | ((value: undefined) => void) = null;
    const trigger = () => {
        if (resolver !== null) {
            resolver(undefined);
        }
        resolver = null;
        promise = null;
    };
    const get = () => {
        if (promise === null) {
            promise = new Promise(resolve => (resolver = resolve));
        }
        return promise;
    };
    return { get, trigger };
};

// FIXME: Use more precise status
// enum TransmissionStatus {
//     Ok = 'Ok',
//     Error = 'Error',
// }

// type SendMessageOptions = {
//     waitAnswer?: boolean;
// };

type SendMessageHandlers = {
    onSuccess?: () => void;
    onError?: () => void;
};

export type SendMessage = (
    msg: unknown,
    handlers?: SendMessageHandlers,
) => void;

type Close = () => void;

type ConnectOptions = {
    // Delay before reconnecting
    timeout?: number;
    // Called whenever a message is received
    onMessage?: (message: string) => void;
    // Called each time the WS status change
    onStatusChange?: (status: WebSocketStatus) => void;
    // Called each time the connection is established
    onConnect?: (sendMessage: SendMessage) => void;
};

const connect = (
    url: string,
    options: ConnectOptions = {},
): [SendMessage, Close] => {
    const timeout = 'timeout' in options ? options.timeout : 5000;
    const { onMessage, onStatusChange, onConnect } = options;
    const connectedEvent = makeEvent();

    let ws: null | WebSocket = null;

    let status = WebSocketStatus.Initial;
    const setStatus = (newStatus: WebSocketStatus) => {
        const previousStatus = status;
        status = newStatus;
        if (status !== previousStatus) {
            // console.log('Status', status);
            if (typeof onStatusChange === 'function') {
                onStatusChange(status);
            }
            if (status === WebSocketStatus.Connected) {
                connectedEvent.trigger();
            }
        }
    };

    // If possible, it's preferable to use idempotent operation to the
    // server.
    // Maybe we should add a timeout to limit how old the message are
    // in queue. For instance, we could choose to cancel a sync
    // operation if it happen too late, because we can attempt to sync
    // again later from the current state.
    const sendMessage: SendMessage = async (
        message: unknown,
        handlers: SendMessageHandlers = {},
    ) => {
        const data = JSON.stringify(message);
        const { onSuccess, onError } = handlers;

        if (status !== WebSocketStatus.Connected) {
            // console.log('WS not connected. Waiting');
            await connectedEvent.get();
            // console.log('WS is back');
        }
        if (ws === null) {
            // console.error('unexpected null websocket');
            return;
        }

        let failed = false;
        try {
            // Here if we fail, then we have no idea if the server
            // got the message or not.
            // console.log('Sending message');
            ws.send(data);
            // console.log('Message sent');
        } catch (e) {
            failed = true;
            console.error(e);
        }
        if (!failed) {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
        } else {
            if (typeof onError === 'function') {
                onError();
            }
        }
    };

    const handleMessage = (event: MessageEvent) => {
        if (typeof onMessage === 'function' && typeof event.data === 'string') {
            onMessage(event.data);
        }
    };

    let running = true;
    const connectLoop = () => {
        if (!running) return;
        setStatus(WebSocketStatus.Connecting);
        ws = null;
        try {
            ws = new WebSocket(url);
        } catch (e) {
            console.error(e);
            setStatus(WebSocketStatus.Disconnected);
            setTimeout(connectLoop, timeout);
            return;
        }
        ws.addEventListener('open', () => {
            setStatus(WebSocketStatus.Connected);
            if (typeof onConnect === 'function') {
                onConnect(sendMessage);
            }
        });
        ws.addEventListener('message', handleMessage);
        ws.addEventListener('close', () => {
            if (ws !== null) {
                ws = null;
                setStatus(WebSocketStatus.Disconnected);
                setTimeout(connectLoop, timeout);
            }
        });
        ws.addEventListener('error', () => {
            if (ws !== null) {
                ws = null;
                setStatus(WebSocketStatus.Disconnected);
                setTimeout(connectLoop, timeout);
            }
        });
    };
    connectLoop();
    const close = () => {
        console.log('Closing');
        running = false;
        if (ws !== null) {
            ws.close();
        }
    };
    return [sendMessage, close];
};

export { WebSocketStatus, connect };
