import { Dimensions, GeoInfo, State } from '@/common/types';
import { getView } from '@/common/syncable';

type ChangeCommon = {
    requestId: string;
};

export type CreateSectionChange = ChangeCommon & {
    command: 'CREATE-SECTION';
    id: string;
    journal: string;
    title: string;
};

export type DeleteSectionChange = ChangeCommon & {
    command: 'DELETE-SECTION';
    id: string;
};

export type RenameSectionChange = ChangeCommon & {
    command: 'RENAME-SECTION';
    id: string;
    title: string;
};

export type CreateMediaChange = ChangeCommon & {
    command: 'CREATE-MEDIA';
    id: string;
    section: string;
    type: 'photo';
    caption: string;
    timestamp: number;
    geo: null | GeoInfo;
    dimensions: null | Dimensions;
};

export type DeleteMediaChange = ChangeCommon & {
    command: 'DELETE-MEDIA';
    id: string;
};

export type SetMediaCaptionChange = ChangeCommon & {
    command: 'SET-MEDIA-CAPTION';
    id: string;
    caption: string;
};

export type StarMediaChange = ChangeCommon & {
    command: 'STAR-MEDIA';
    id: string;
    value: boolean;
};

export type MoveMediaChange = ChangeCommon & {
    command: 'MOVE-MEDIA';
    id: string;
    section: string;
};

export type Change =
    | CreateSectionChange
    | DeleteSectionChange
    | RenameSectionChange
    | CreateMediaChange
    | DeleteMediaChange
    | SetMediaCaptionChange
    | StarMediaChange
    | MoveMediaChange;

/** Get all the changes that are not yet transmitted to the server */
export const extractChanges = (state: State): Array<Change> => {
    const changes: Array<Change> = [];
    const clocks = state.clocks;
    for (const section of Object.values(state.sections)) {
        // We shouldn't have creation.pending && deletion.pending
        if (section.creation.pending) {
            /** Section to be created */
            const [_isTitlePending, title] = getView(section.title, clocks);
            const req = `create-section:${section.id}`;
            changes.push({
                requestId: req,
                command: 'CREATE-SECTION',
                id: section.id,
                journal: section.journal,
                title,
            });
        } else if (section.deletion.pending) {
            /** Section to be deleted */
            const req = `delete-section:${section.id}`;
            changes.push({
                requestId: req,
                command: 'DELETE-SECTION',
                id: section.id,
            });
        } else {
            const [isTitlePending, title] = getView(section.title, clocks);
            if (isTitlePending) {
                /** Section to rename */
                const req = `rename-section:${section.id}`;
                changes.push({
                    requestId: req,
                    command: 'RENAME-SECTION',
                    id: section.id,
                    title,
                });
            }
        }
    }
    for (let media of Object.values(state.mediaInfo)) {
        const link = state.mediaLink[media.id];
        const importInfo = state.mediaImport[media.id];
        // We shouldn't have creation.pending && deletion.pending
        if (media.creation.pending) {
            const uri = importInfo.stages.length
                ? importInfo.stages[0].uri
                : null;
            if (uri !== null) {
                /** Media to add to a section */
                const [_sectionIsPending, section] = getView(
                    link.section,
                    clocks,
                );
                const [_captionIsPending, caption] = getView(
                    media.caption,
                    clocks,
                );
                const req = `create-media:${media.id}`;
                // Note: No known URI at this stage.
                changes.push({
                    requestId: req,
                    command: 'CREATE-MEDIA',
                    id: media.id,
                    section,
                    type: 'photo',
                    caption,
                    timestamp: media.timestamp,
                    geo: media.geo,
                    dimensions: media.dimensions,
                });
            }
        } else if (media.deletion.pending) {
            /** Media to remove */
            const req = `delete-media:${media.id}`;
            changes.push({
                requestId: req,
                command: 'DELETE-MEDIA',
                id: media.id,
            });
        } else {
            const [captionIsPending, caption] = getView(media.caption, clocks);
            if (captionIsPending) {
                /** Media caption to update */
                const req = `set-media-caption:${media.id}`;
                changes.push({
                    requestId: req,
                    command: 'SET-MEDIA-CAPTION',
                    id: media.id,
                    caption,
                });
            }
            const [starredIsPending, starred] = getView(media.starred, clocks);
            if (starredIsPending) {
                /** Media to star */
                const req = `star-media:${media.id}`;
                changes.push({
                    requestId: req,
                    command: 'STAR-MEDIA',
                    id: media.id,
                    value: starred,
                });
            }
            const [sectionIsPending, section] = getView(link.section, clocks);
            if (sectionIsPending) {
                /** Media to move to another section */
                const req = `move-media:${media.id}`;
                changes.push({
                    requestId: req,
                    command: 'MOVE-MEDIA',
                    id: media.id,
                    section,
                });
            }
        }
    }
    return changes;
};
