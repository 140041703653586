import {
    State,
    GeoInfo,
    Dimensions,
    JournalId,
    SectionId,
    MediaId,
    ImportState,
    WebSocketStatus,
    MediaQuality,
    ImportStageId,
} from '@/common/types';

export const SET_CLOCKS = 'COMMON/SET_CLOCKS';

type ClocksChange = {
    sync?: number;
    pending?: number;
};

type SetClocksAction = {
    type: typeof SET_CLOCKS;
    clocks: ClocksChange;
};

export const setClocks = (clocks: ClocksChange): SetClocksAction => ({
    type: SET_CLOCKS,
    clocks,
});

export const SELECT_JOURNAL = 'COMMON/SELECT_JOURNAL';

type SelectJournalAction = {
    type: typeof SELECT_JOURNAL;
    id: JournalId;
};

export const selectJournal = (id: JournalId) => ({
    type: SELECT_JOURNAL,
    id,
});

export const SET_CONNECTION_STATUS = 'COMMON/SET_CONNECTION_STATUS';

type SetConnectionStatusAction = {
    type: typeof SET_CONNECTION_STATUS;
    status: WebSocketStatus;
};

export const setConnectionStatus = (
    status: WebSocketStatus,
): SetConnectionStatusAction => ({
    type: SET_CONNECTION_STATUS,
    status,
});

export const RESET = 'COMMON/RESET';

type ResetAction = {
    type: typeof RESET;
    model: number;
};

export const reset = (model: number): ResetAction => ({
    type: RESET,
    model,
});

export const LOAD = 'COMMON/LOAD';

type LoadAction = {
    type: typeof LOAD;
    state: State;
};

export const load = (state: State): LoadAction => ({
    type: LOAD,
    state,
});

export const SET_VERSION = 'COMMON/SET_VERSION';

type SetVersionAction = {
    type: typeof SET_VERSION;
    version: number;
};

export const setVersion = (version: number): SetVersionAction => ({
    type: SET_VERSION,
    version,
});

// -- Journal ------------------------------------------------------------------

export const DECLARE_JOURNAL = 'COMMON/DECLARE_JOURNAL';

type DeclareJournalAction = {
    type: typeof DECLARE_JOURNAL;
    id: JournalId;
    version: number;
    title: string;
    subtitle: string;
};

export const declareJournal = (
    id: JournalId,
    version: number,
    title: string,
    subtitle: string,
): DeclareJournalAction => ({
    type: DECLARE_JOURNAL,
    id,
    version,
    title,
    subtitle,
});

// -- Section ------------------------------------------------------------------

export const DECLARE_SECTION = 'COMMON/DECLARE_SECTION';

type DeclareSectionAction = {
    type: typeof DECLARE_SECTION;
    id: SectionId;
    version: number;
    journal: JournalId;
    title: string;
};

export const declareSection = (
    id: SectionId,
    version: number,
    journal: JournalId,
    title: string,
): DeclareSectionAction => ({
    type: DECLARE_SECTION,
    id,
    version,
    journal,
    title,
});

export const REMOVE_SECTION = 'COMMON/REMOVE_SECTION';

type RemoveSectionAction = {
    type: typeof REMOVE_SECTION;
    id: SectionId;
};

export const removeSection = (id: SectionId): RemoveSectionAction => ({
    type: REMOVE_SECTION,
    id,
});

export const CREATE_SECTION = 'COMMON/CREATE_SECTION';

type CreateSectionAction = {
    type: typeof CREATE_SECTION;
    id: SectionId;
    journal: JournalId;
    title: string;
};

export const createSection = (
    id: SectionId,
    journal: JournalId,
    title: string,
): CreateSectionAction => ({
    type: CREATE_SECTION,
    id,
    journal,
    title,
});

export const DELETE_SECTION = 'COMMON/DELETE_SECTION';

type DeleteSectionAction = {
    type: typeof DELETE_SECTION;
    id: SectionId;
};

export const deleteSection = (id: SectionId): DeleteSectionAction => ({
    type: DELETE_SECTION,
    id,
});

export const CANCEL_DELETE_SECTION = 'COMMON/CANCEL_DELETE_SECTION';

type CancelDeleteSectionAction = {
    type: typeof CANCEL_DELETE_SECTION;
    id: SectionId;
};

export const cancelDeleteSection = (
    id: SectionId,
): CancelDeleteSectionAction => ({
    type: CANCEL_DELETE_SECTION,
    id,
});

export const RENAME_SECTION = 'COMMON/RENAME_SECTION';

type RenameSectionAction = {
    type: typeof RENAME_SECTION;
    id: SectionId;
    title: string;
};

export const renameSection = (
    id: SectionId,
    title: string,
): RenameSectionAction => ({
    type: RENAME_SECTION,
    id,
    title,
});

// -- Media --------------------------------------------------------------------

export const DECLARE_MEDIA = 'COMMON/DECLARE_MEDIA';

type DeclareMediaAction = {
    type: typeof DECLARE_MEDIA;
    id: MediaId;
    version: number;
    author: string;
    section: SectionId;
    caption: string;
    starred: boolean;
    starCount: number;
    type_: 'photo' | 'video'; // payload: { ... } instead?
    uri: string;
    thumbnailUri: string;
    timestamp: number;
    timestamp_added: number;
    geo: null | GeoInfo;
    dimensions: null | Dimensions;
};

export const declareMedia = (
    id: MediaId,
    version: number,
    author: string,
    section: SectionId,
    caption: string,
    starred: boolean,
    starCount: number,
    type_: 'photo' | 'video',
    uri: string,
    thumbnailUri: string,
    timestamp: number,
    timestamp_added: number,
    geo: null | GeoInfo,
    dimensions: null | Dimensions,
): DeclareMediaAction => ({
    type: DECLARE_MEDIA,
    id,
    version,
    author,
    section,
    caption,
    starred,
    starCount,
    type_,
    uri,
    thumbnailUri,
    timestamp,
    timestamp_added,
    geo,
    dimensions,
});

export const REMOVE_MEDIA = 'COMMON/REMOVE_MEDIA';

type RemoveMediaAction = {
    type: typeof REMOVE_MEDIA;
    id: MediaId;
};

export const removeMedia = (id: MediaId): RemoveMediaAction => ({
    type: REMOVE_MEDIA,
    id,
});

export const CHANGE_STARRED_MEDIA = 'COMMON/CHANGE_STARRED_MEDIA';

type ChangeStarredMediaAction = {
    type: typeof CHANGE_STARRED_MEDIA;
    id: MediaId;
    value: boolean;
};

export const changeStarredMedia = (id: MediaId, value: boolean) => ({
    type: CHANGE_STARRED_MEDIA,
    id,
    value,
});

// FIXME: Take a list of media?
// FIXME: Section action, rather than media action?
export const MOVE_MEDIA = 'COMMON/MOVE_MEDIA';

type MoveMediaAction = {
    type: typeof MOVE_MEDIA;
    id: MediaId;
    section: SectionId;
};

export const moveMedia = (
    id: MediaId,
    section: SectionId,
): MoveMediaAction => ({
    type: MOVE_MEDIA,
    id,
    section,
});

export const DELETE_MEDIA = 'COMMON/DELETE_MEDIA';

type DeleteMediaAction = {
    type: typeof DELETE_MEDIA;
    id: MediaId;
};

export const deleteMedia = (id: MediaId): DeleteMediaAction => ({
    type: DELETE_MEDIA,
    id,
});

export const CREATE_MEDIA = 'COMMON/CREATE_MEDIA';

type CreateMediaAction = {
    type: typeof CREATE_MEDIA;
    id: MediaId;
    section: SectionId;
    uri: string; // local uri (ph://)
    caption: string;
    imageSize: MediaQuality;
    timestamp: number;
    geo: null | GeoInfo;
    dimensions: null | Dimensions;
    quality: MediaQuality;
};

export const createMedia = (
    id: MediaId,
    section: SectionId,
    uri: string,
    caption: string,
    imageSize: MediaQuality,
    timestamp: number,
    geo: null | GeoInfo,
    dimensions: null | Dimensions,
    quality: MediaQuality,
): CreateMediaAction => ({
    type: CREATE_MEDIA,
    id,
    section,
    uri,
    caption,
    imageSize,
    timestamp,
    geo,
    dimensions,
    quality,
});

export const SET_MEDIA_CAPTION = 'COMMON/SET_MEDIA_CAPTION';

type SetMediaCaptionAction = {
    type: typeof SET_MEDIA_CAPTION;
    id: MediaId;
    caption: string;
};

export const setMediaCaption = (
    id: MediaId,
    caption: string,
): SetMediaCaptionAction => ({
    type: SET_MEDIA_CAPTION,
    id,
    caption,
});

export const SET_IMPORT_STAGE_STATE = 'COMMON/SET_IMPORT_STAGE_STATE';

type SetImportStateAction = {
    type: typeof SET_IMPORT_STAGE_STATE;
    id: MediaId;
    stageId: ImportStageId;
    state: ImportState;
};

export const setImportStageState = (
    id: MediaId,
    stageId: ImportStageId,
    state: ImportState,
): SetImportStateAction => ({
    type: SET_IMPORT_STAGE_STATE,
    id,
    stageId,
    state,
});

export const UPDATE_IMPORT_PROGRESS = 'COMMON/UPDATE_IMPORT_PROGRESS';

type UpdateImportProgressAction = {
    type: typeof UPDATE_IMPORT_PROGRESS;
    id: MediaId;
    stageId: ImportStageId;
    progress: number;
};

export const updateImportProgress = (
    id: SectionId,
    stageId: ImportStageId,
    progress: number,
): UpdateImportProgressAction => ({
    type: UPDATE_IMPORT_PROGRESS,
    id,
    stageId,
    progress,
});

export const REMOVE_IMPORT_STAGE = 'COMMON/REMOVE_IMPORT_STAGE';

type RemoveImportStageAction = {
    type: typeof REMOVE_IMPORT_STAGE;
    id: MediaId;
    stageId: ImportStageId;
};

export const removeImportStage = (
    id: SectionId,
    stageId: ImportStageId,
): RemoveImportStageAction => ({
    type: REMOVE_IMPORT_STAGE,
    id,
    stageId,
});

export const RETRY_IMPORT = 'COMMON/RETRY_IMPORT';

type RetryImportAction = {
    type: typeof RETRY_IMPORT;
    id: MediaId;
};

export const retryImport = (id: SectionId): RetryImportAction => ({
    type: RETRY_IMPORT,
    id,
});

export type ActionTypes =
    | SetClocksAction
    | SelectJournalAction
    | SetConnectionStatusAction
    | ResetAction
    | LoadAction
    | SetVersionAction
    | DeclareJournalAction
    | DeclareSectionAction
    | RemoveSectionAction
    | DeclareMediaAction
    | ChangeStarredMediaAction
    | MoveMediaAction
    | DeleteMediaAction
    | SetMediaCaptionAction
    | CreateSectionAction
    | DeleteSectionAction
    | CancelDeleteSectionAction
    | RenameSectionAction
    | CreateMediaAction
    | RemoveMediaAction
    | SetImportStateAction
    | UpdateImportProgressAction
    | RemoveImportStageAction
    | RetryImportAction;
