export class ApiError extends Error {
    code: string;
    data: unknown;
    constructor(code: string, data: unknown) {
        super(code);
        this.code = code;
        this.data = data;
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}
