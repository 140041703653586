export enum LoginStatus {
    Unconfigured = 'Unconfigured',
    Connecting = 'Connecting',
    Reconnecting = 'Reconnecting',
    Connected = 'Connected',
    Disconnected = 'Disconnected',
}

export type State = {
    status: LoginStatus;
    user: string;
    password: string;
    reason: string;
};
