import { reset } from '../common/actions';

export const RESTORE_STATE = 'LOGIN/RESTORE_STATE';

export type RestoreStateAction = {
    type: typeof RESTORE_STATE;
    user: string;
    password: string;
};

export const restoreState = (
    user: string,
    password: string,
): RestoreStateAction => ({
    type: RESTORE_STATE,
    user,
    password,
});

export const LOGIN = 'LOGIN/LOGIN';

export type LoginAction = {
    type: typeof LOGIN;
    user: string;
    password: string;
};

export const login = (user: string, password: string): LoginAction => ({
    type: LOGIN,
    user,
    password,
});

export const LOGIN_SUCCESSFUL = 'LOGIN/LOGIN_SUCCESSFUL';

export type LoginSuccesfulAction = {
    type: typeof LOGIN_SUCCESSFUL;
};

export const loginSuccesful = (): LoginSuccesfulAction => ({
    type: LOGIN_SUCCESSFUL,
});

export const DISCONNECT = 'LOGIN/DISCONNECT';

export type DisconnectAction = {
    type: typeof DISCONNECT;
    reason: string;
};

export const disconnect = (reason: string): DisconnectAction => ({
    type: DISCONNECT,
    reason,
});

export const terminateSession = (reason: string) => (dispatch: any) => {
    dispatch(reset(0));
    dispatch(disconnect(reason));
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('auth');
    }
};

export type ActionTypes =
    | RestoreStateAction
    | LoginAction
    | LoginSuccesfulAction
    | DisconnectAction;
