import * as yup from 'yup';

import { Server } from '@/common/types';
import { Change } from '@/common/changes';
import { Command } from '@/common/commands'; // rename to Update?

const doRequest = async <T>(
    server: Server,
    method: string,
    params: unknown,
    schema: yup.AnySchema,
): Promise<T> => {
    const response = await server.send(method, params);
    try {
        return schema.validateSync(response);
    } catch (e) {
        throw new Error('Invalid value received from the server');
    }
};

export type LoginResponse = {
    token: string;
};

const loginSchema = yup
    .object({
        token: yup.string().defined(),
    })
    .strict()
    .noUnknown();

export const login = async (server: Server, user: string, password: string) =>
    await doRequest<LoginResponse>(
        server,
        'login',
        {
            user,
            password,
        },
        loginSchema,
    );

export type SyncResponse = {
    model: number;
    version: number;
    changes: Array<Command>;
    errors: Record<string, string>;
};

// FIXME: unfinished
const syncSchema = yup
    .object({
        model: yup.number().integer().min(0),
        version: yup.number().integer().min(0),
        changes: yup.array().defined(),
        errors: yup.object().defined(),
    })
    .strict()
    .noUnknown();

export const sync = async (
    server: Server,
    model: number,
    version: number,
    changes: Array<Change>,
) =>
    await doRequest<SyncResponse>(
        server,
        'sync',
        {
            model,
            version,
            changes,
        },
        syncSchema,
    );
