// FIXME: Also in app/

import { State } from './state';

import { useSelector } from 'react-redux';

import { Clocks } from './common/types';

export const useClocks = (): Clocks => {
    return useSelector((state: State) => state.main.clocks);
};
