import * as yup from 'yup';

import { Value } from '@/common/syncable';
import { Change } from '@/common/changes';
import { Command } from '@/common/commands';

export type Server = {
    send: (method: string, params: unknown) => Promise<unknown>;
};

export type RequestId = string;

export type UpdateData = {
    version: number;
    changes: Array<Command>;
    errors: null | Record<RequestId, string>;
};

const updateDataSchema = yup
    .object({
        version: yup.number().defined(),
        changes: yup.array().defined(),
        errors: yup.object(),
    })
    .strict()
    .noUnknown();

export const parseUpdateData = (data: unknown): UpdateData =>
    updateDataSchema.validateSync(data);

export type LoginRequest = {
    method: 'login';
    user: string;
    password: string;
};

export type LoginResponse = {
    method: 'login';
    token: string;
};

const loginResultSchema = yup
    .object({
        method: yup.mixed<'login'>().oneOf(['login']).defined(),
        token: yup.string().defined(),
    })
    .strict()
    .noUnknown();

export type SyncRequest = {
    method: 'sync';
    model: number;
    version: number;
    changes: Array<Change>;
};

export type SyncResponse = {
    method: 'sync';
    changes: Array<Command>;
};

const syncResultSchema = yup
    .object({
        method: yup.mixed<'sync'>().oneOf(['sync']).defined(),
        changes: yup.array().of(yup.mixed()).defined(),
    })
    .strict()
    .noUnknown();

export type Request = LoginRequest | SyncRequest;

export type Response = LoginResponse | SyncResponse;

export type RequestMethod = Request['method'];

export type SendRequest = (request: Request) => Promise<unknown>;

export type Result = {
    id: string;
    result: unknown;
};

const resultSchema = yup
    .object({
        id: yup.string().defined(),
        result: yup.mixed(), // we accept anything
    })
    .strict()
    .noUnknown();

export type Error = {
    id: string;
    error: {
        code: string;
        data?: unknown; // or drop 'data' to use a flat structure?
    };
};

const errorSchema = yup
    .object({
        id: yup.string().defined(),
        error: yup
            .object({
                code: yup.string().defined(),
                data: yup.mixed(), // we accept anything
            })
            .strict()
            .noUnknown(),
    })
    .strict()
    .noUnknown();

export function parseResponse(
    method: RequestMethod,
    data: unknown,
): LoginResponse | SyncResponse | Error {
    try {
        return errorSchema.validateSync(data);
    } catch (e) {
        // pass
    }
    let result: Result;
    try {
        result = resultSchema.validateSync(data);
    } catch (e) {
        throw new Error('Unexpected message from server');
    }
    switch (method) {
        case 'login':
            return loginResultSchema.validateSync(result.result);
        case 'sync':
            return syncResultSchema.validateSync(result.result);
    }
}

export type Message = Request | Result | Error;

export type AuthParameters = {
    user: string;
    password: string;
};

export type UseConnectionOptions = {
    onLoginSuccesful?: (token?: string) => void;
    onWrongCredentials?: () => void;
};

export enum WebSocketStatus {
    Initial = 'Initial',
    Connecting = 'Connecting',
    Connected = 'Connected',
    Disconnected = 'Disconnected',
    Error = 'Error',
    Terminated = 'Terminated',
}

export type JournalId = string;
export type SectionId = string;
export type MediaId = string;
export type ImportStageId = string;

export enum ImportState {
    Waiting = 'Waiting',
    Uploading = 'Uploading',
    Failed = 'Failed',
}

export enum MediaQuality {
    Preview = 'Preview',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    Full = 'Full',
}

// // A local media originating from an album
// export type DeviceMedia = {
//     uri: string,
//     timestamp: number,
//     geo: null | {
//         lat: number,
//         lng: number
//     }
// };

// export type PhotosPickerState = {
//     choices: null | Array<DeviceMedia>,
//     selection: Set<string>
// };

export type Clocks = {
    sync: number;
    pending: number;
};

export type Dimensions = {
    width: number;
    height: number;
};

export type GeoInfo = {
    longitude: number;
    latitude: number;
};

export type OperationState = {
    pending: boolean;
    error: null | string;
};

export type JournalState = {
    id: JournalId;
    version: null | number;
    creation: OperationState;
    deletion: OperationState;
    title: Value<string>;
    subtitle: Value<string>;
};

export type SectionState = {
    id: SectionId;
    version: null | number;
    journal: JournalId;
    creation: OperationState;
    deletion: OperationState;
    title: Value<string>;
};

export type MediaInfoState = {
    id: MediaId;
    version: null | number;
    creation: OperationState;
    deletion: OperationState;
    timestamp: number;
    timestamp_added: null | number;
    author: null | string;
    type: MediaType;
    thumbnailUri: null | string;
    uri: null | string;
    caption: Value<string>;
    starred: Value<boolean>;
    starCount: number;
    geo: null | GeoInfo;
    dimensions: null | Dimensions;
};

export type MediaLinkState = {
    id: MediaId;
    section: Value<SectionId>;
};

// FIXME: Shouldn't that be moved outside common/ ?
export type ImportStage = {
    id: ImportStageId;
    uri: string;
    state: ImportState;
    quality: MediaQuality; // quality at this stage (Preview then the one requested)
    progress: number;
};

// FIXME: Shouldn't that be moved outside common/ ?
export type MediaImportState = {
    id: MediaId;
    pending: boolean; // true means the media is not yet registered on server
    quality: MediaQuality; // requested quality
    stages: Array<ImportStage>;
};

export enum DownloadState {
    Pending,
    Downloading,
    Failed,
    Downloaded,
}

export enum MediaType {
    Photo,
    Video,
}

export type State = {
    connectionStatus: WebSocketStatus;
    model: number;
    version: number;
    clocks: Clocks;
    currentJournal: null | JournalId;
    journals: {
        [key: string]: JournalState;
    };
    sections: {
        [key: string]: SectionState;
    };
    mediaInfo: {
        [key: string]: MediaInfoState;
    };
    mediaLink: {
        [key: string]: MediaLinkState;
    };
    mediaImport: {
        [key: string]: MediaImportState;
    };
};
