// TODO: Upload photo

import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Store } from 'redux';

import * as yup from 'yup';

import { State } from '@/state';
import {
    AuthParameters,
    UseConnectionOptions,
    UpdateData,
    parseUpdateData,
    Server,
} from '@/common/types';
import { connect, SendMessage } from '@/common/connect';
import { reset, setConnectionStatus } from '@/common/actions';
import { applyCommands, update } from '@/common/update';
import { login } from '@/common/calls';
import { ApiError } from '@/common/error';

type RequestPayload = {
    id?: string; // undefined = notification
    method: string;
    params: Record<string, unknown> | Array<unknown>;
};

const requestPayloadSchema = yup
    .object({
        id: yup.string(),
        method: yup.string().defined(),
        params: yup.mixed().defined(),
    })
    .strict()
    .noUnknown();

const parseRequestPayload = (data: unknown): RequestPayload =>
    requestPayloadSchema.validateSync(data);

type ResponsePayload = {
    id: string;
    result?: unknown;
    error?: { code: string; data: unknown };
};

const responsePayloadSchema = yup
    .object({
        id: yup.string().defined(),
        result: yup.mixed(),
        error: yup.object({
            code: yup.string().defined(),
            data: yup.mixed(),
        }),
    })
    .strict()
    .noUnknown();

const parseResponsePayload = (data: unknown): ResponsePayload =>
    responsePayloadSchema.validateSync(data);

type Handler = (data: unknown) => void;

type Handlers = Record<string, Handler>;

const processMessage = (
    handlers: Handlers,
    method: string,
    params: unknown,
) => {
    const handler = handlers[method];
    if (handler === undefined) {
        console.log(`Unknown notification ${JSON.stringify(method)}`);
        return;
    }
    handler(params);
};

const pause = (duration: number) =>
    new Promise(resolve => setTimeout(resolve, duration));

type ConnectionCallbacks = {
    close: () => void;
    request: (method: string, params: unknown) => Promise<unknown>;
};

/** Loop to keep the state synchronized with the server */
const setup = (
    /** Parameters to connect to the API */
    auth: AuthParameters,
    options: UseConnectionOptions,
    store: Store<State>,
): ConnectionCallbacks => {
    const { onLoginSuccesful, onWrongCredentials } = options;
    let sendMessage: null | SendMessage = null;
    // Those waiting for a response
    const waiters: Map<
        string,
        {
            resolve: (value: unknown) => void;
            reject: (error: unknown) => void;
        }
    > = new Map();
    let nextRequestId = 1;
    // The server can call these functions
    const handlers = {
        update: (params: unknown) => {
            let data: UpdateData;
            try {
                data = parseUpdateData(params);
            } catch (e) {
                return;
            }
            applyCommands(data.version, data.changes, null, store.dispatch);
        },
    };
    const sendRequest = (method: string, params: unknown) => {
        if (sendMessage === null) {
            throw new Error('WS not ready');
        }
        const requestId = (nextRequestId++).toString();
        const message = { id: requestId, method, params };
        const response = new Promise<unknown>((resolve, reject) => {
            // Add a timeout? (if we never receive an answer from the server)
            waiters.set(requestId, { resolve, reject });
        });
        const onSuccess = () => {
            // Message was sent
        };
        const onError = () => {
            // Message failed to be sent (no idea if the server got it)
            const entry = waiters.get(requestId);
            if (entry !== undefined) {
                // FIXME: Better error type!
                entry.reject(new Error('Call failed'));
                waiters.delete(requestId);
            }
        };
        sendMessage(message, {
            onSuccess,
            onError,
        });
        return response;
    };
    const server: Server = {
        send: sendRequest,
    };
    let refresh = true;
    let loggedIn = false;
    let token: null | string = null;
    const [sendMessage_, closeConnection] = connect(WS_URL, {
        onConnect: async sendMessage_ => {
            sendMessage = sendMessage_;
            refresh = true;
            loggedIn = false;
            try {
                const result = await login(
                    server,
                    auth.user,
                    token ?? auth.password,
                );
                token = result.token;
                loggedIn = true;
                if (typeof onLoginSuccesful === 'function') {
                    try {
                        onLoginSuccesful(token);
                    } catch (e) {
                        // FIXME
                    }
                }
            } catch (e) {
                loggedIn = false;
                if (typeof onWrongCredentials === 'function') {
                    try {
                        onWrongCredentials();
                    } catch (e) {
                        // FIXME
                    }
                }
            }
        },
        onMessage: (raw: string) => {
            // console.log('MSG', raw);
            if (sendMessage === null) return;
            const data = JSON.parse(raw);
            try {
                const req = parseRequestPayload(data);
                if (req.id === undefined) {
                    processMessage(handlers, req.method, req.params);
                } else {
                    console.error('Received a request!');
                }
                return;
            } catch (e) {
                // ok
            }
            let info: ResponsePayload;
            try {
                info = parseResponsePayload(data);
            } catch (e) {
                console.error('Ignoring unexpected message');
                return;
            }
            const entry = waiters.get(info.id);
            if (entry === undefined) {
                console.error('Received a response for an unknown request');
                return;
            }
            const { result, error } = info;
            if (result !== undefined) {
                entry.resolve(result);
            } else if (error !== undefined) {
                entry.reject(new ApiError(error.code, error.data));
            }
            waiters.delete(info.id);
        },
        onStatusChange: state => {
            // document.title = `Status: ${state}`;
            store.dispatch(setConnectionStatus(state));
        },
    });
    sendMessage = sendMessage_;
    // periodic updates
    let running = true;
    const loop = async () => {
        while (running) {
            if (loggedIn) {
                const state = store.getState().main;
                let success = false;
                try {
                    success = await update(
                        server,
                        state,
                        !refresh,
                        store.dispatch,
                    );
                } catch (e) {
                    console.error('Failed to update', e);
                }
                if (success) {
                    refresh = false;
                }
            }
            // FIXME: Lower value, with automatic throttle as protection?
            await pause(100);
        }
    };
    loop();
    const close = () => {
        running = false;
        closeConnection();
    };
    return {
        close: close,
        request: sendRequest,
    };
};

/** Loop to keep the state synchronized with the server */
export const useConnection = (
    parameters: null | AuthParameters,
    options: UseConnectionOptions = {},
) => {
    const store = useStore();
    const [api, setApi] = useState<null | ConnectionCallbacks>(null);
    useEffect(() => {
        if (parameters === null) {
            setApi(null);
        } else {
            const api = setup(parameters, options, store);
            setApi(api);
            return () => {
                api.close();
                store.dispatch(reset(0));
                setApi(null);
            };
        }
    }, [parameters]);
    return api;
};
