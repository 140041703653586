import update from 'immutability-helper';

import { State, LoginStatus } from '@/login-logic/types';
import {
    ActionTypes,
    RESTORE_STATE,
    LOGIN,
    LOGIN_SUCCESSFUL,
    DISCONNECT,
} from '@/login-logic/actions';

const initialState: State = {
    status: LoginStatus.Unconfigured,
    user: '',
    password: '',
    reason: '',
};

export const reducer = (
    state: State = initialState,
    action: ActionTypes,
): State => {
    switch (action.type) {
        case RESTORE_STATE:
            return update(initialState, {
                user: { $set: action.user },
                password: { $set: action.password },
                status: { $set: LoginStatus.Reconnecting },
            });
        case LOGIN:
            return update(state, {
                user: { $set: action.user },
                password: { $set: action.password },
                status: { $set: LoginStatus.Connecting },
                reason: { $set: '' },
            });
        case LOGIN_SUCCESSFUL: {
            return update(state, {
                status: { $set: LoginStatus.Connected },
                reason: { $set: '' },
            });
        }
        case DISCONNECT:
            return update(state, {
                password: { $set: '' },
                status: { $set: LoginStatus.Disconnected },
                reason: { $set: action.reason },
            });
        default:
            return state;
    }
};
