import update from 'immutability-helper';

import uuid4 from 'uuid/v4';

import {
    OperationState,
    State,
    MediaType,
    MediaImportState,
    ImportState,
    WebSocketStatus,
    MediaQuality,
} from './types';

import {
    ActionTypes,
    SET_CLOCKS,
    SELECT_JOURNAL,
    SET_CONNECTION_STATUS,
    RESET,
    LOAD,
    SET_VERSION,
    DECLARE_JOURNAL,
    DECLARE_SECTION,
    REMOVE_SECTION,
    DECLARE_MEDIA,
    REMOVE_MEDIA,
    CHANGE_STARRED_MEDIA,
    SET_MEDIA_CAPTION,
    MOVE_MEDIA,
    DELETE_MEDIA,
    CREATE_SECTION,
    DELETE_SECTION,
    CANCEL_DELETE_SECTION,
    RENAME_SECTION,
    CREATE_MEDIA,
    SET_IMPORT_STAGE_STATE,
    UPDATE_IMPORT_PROGRESS,
    REMOVE_IMPORT_STAGE,
    RETRY_IMPORT,
    removeSection,
    removeMedia,
} from '@/common/actions';

import {
    // Value,
    create as createValue,
    set as setValue,
    push as pushValue,
} from '@/common/syncable';

const defaultOperationState: OperationState = {
    pending: false,
    error: null,
};

const initialState: State = {
    connectionStatus: WebSocketStatus.Initial,
    model: 0,
    version: 0,
    clocks: {
        sync: 0,
        pending: 0,
    },
    currentJournal: '00000000-0000-0000-0000-000000000000',
    journals: {},
    sections: {},
    mediaInfo: {},
    mediaLink: {},
    mediaImport: {},
};

const filterObject = (
    obj: { [key: string]: any },
    predicate: (k: string, v: any) => boolean,
): { [key: string]: any } => {
    const result: { [key: string]: any } = {};
    for (let [k, v] of Object.entries(obj)) {
        if (predicate(k, v)) {
            result[k] = v;
        }
    }
    return result;
};

export const cleanupState = (state: State): State => {
    return update(state, {
        mediaImport: mediaImport => {
            const result: { [key: string]: MediaImportState } = {};
            Object.entries(mediaImport).forEach(([key, value]) => {
                if (
                    value.stages.length &&
                    value.stages[0].state === ImportState.Uploading
                ) {
                    value = update(value, {
                        stages: {
                            0: {
                                state: { $set: ImportState.Failed },
                            },
                        },
                    });
                }
                result[key] = value;
            });
            return result;
        },
    });
};

// FIXME: Actually `action` can take other values. But here, we are
// supposed to be neutral regarding other reducers, thus maybe we need
// the `ActionTypes | any` type or something like that. How are we
// supposed to type that?
export const reducer = (
    state: State = initialState,
    action: ActionTypes,
): State => {
    switch (action.type) {
        case SET_CLOCKS: {
            if (action.clocks.sync != null) {
                state = update(state, {
                    clocks: { sync: { $set: action.clocks.sync } },
                });
            }
            if (action.clocks.pending != null) {
                state = update(state, {
                    clocks: { pending: { $set: action.clocks.pending } },
                });
            }
            return state;
        }
        case SELECT_JOURNAL:
            return update(state, { currentJournal: { $set: action.id } });
        case SET_CONNECTION_STATUS:
            return update(state, { connectionStatus: { $set: action.status } });
        case RESET:
            return update(initialState, {
                connectionStatus: { $set: state.connectionStatus },
                model: { $set: action.model },
            });
        case LOAD:
            if (!('model' in action.state)) return state;
            // FIXME: Dangerous
            return update(action.state, {
                connectionStatus: { $set: state.connectionStatus },
            });
        case SET_VERSION:
            return update(state, { version: { $set: action.version } });
        case DECLARE_JOURNAL: {
            const { clocks } = state;
            if (action.id in state.journals) {
                return update(state, {
                    journals: {
                        [action.id]: {
                            version: { $set: action.version },
                            creation: { pending: { $set: false } },
                            deletion: { pending: { $set: false } },
                            title: title =>
                                setValue(title, action.title, clocks),
                            subtitle: subtitle =>
                                setValue(subtitle, action.subtitle, clocks),
                        },
                    },
                });
            } else {
                return update(state, {
                    journals: {
                        [action.id]: {
                            $set: {
                                id: action.id,
                                version: action.version,
                                creation: defaultOperationState,
                                deletion: defaultOperationState,
                                title: createValue(action.title),
                                subtitle: createValue(action.subtitle),
                            },
                        },
                    },
                });
            }
        }
        case DECLARE_SECTION: {
            const { clocks } = state;
            if (action.id in state.sections) {
                return update(state, {
                    sections: {
                        [action.id]: {
                            version: { $set: action.version },
                            creation: { pending: { $set: false } },
                            deletion: { pending: { $set: false } },
                            journal: { $set: action.journal }, // not supposed to change
                            title: title =>
                                setValue(title, action.title, clocks),
                        },
                    },
                });
            } else {
                return update(state, {
                    sections: {
                        [action.id]: {
                            $set: {
                                id: action.id,
                                version: action.version,
                                journal: action.journal,
                                creation: defaultOperationState,
                                deletion: defaultOperationState,
                                title: createValue(action.title),
                            },
                        },
                    },
                });
            }
        }
        case REMOVE_SECTION: {
            // Remove attached media too?
            // FIXME: Should we remove from the target section or the
            // current section? We might be moving the media while
            // removing a related section!
            const media = new Set(
                Object.values(state.mediaLink)
                    .filter(item => item.section.value === action.id)
                    .map(item => item.id),
            );
            return update(state, {
                sections: { $unset: [action.id] },
                mediaImport: d => filterObject(d, (k, _v) => !media.has(k)),
                mediaInfo: d => filterObject(d, (k, _v) => !media.has(k)),
                mediaLink: d => filterObject(d, (k, _v) => !media.has(k)),
            });
        }
        case RENAME_SECTION: {
            const { clocks } = state;
            if (!(action.id in state.sections)) return state;
            return update(state, {
                sections: {
                    [action.id]: {
                        title: title => pushValue(title, action.title, clocks),
                    },
                },
            });
        }
        case DECLARE_MEDIA: {
            const { clocks } = state;
            if (action.id in state.mediaInfo) {
                return update(state, {
                    mediaInfo: {
                        [action.id]: {
                            version: { $set: action.version },
                            creation: { pending: { $set: false } },
                            deletion: { pending: { $set: false } },
                            author: { $set: action.author },
                            thumbnailUri: { $set: action.thumbnailUri },
                            uri: { $set: action.uri },
                            timestamp_added: { $set: action.timestamp_added },
                            caption: caption =>
                                setValue(caption, action.caption, clocks),
                            starred: starred =>
                                setValue(starred, action.starred, clocks),
                            starCount: { $set: action.starCount },
                        },
                    },
                    mediaLink: {
                        [action.id]: {
                            section: section =>
                                setValue(section, action.section, clocks),
                        },
                    },
                    mediaImport: {
                        [action.id]: {
                            pending: { $set: false },
                        },
                    },
                });
            } else {
                return update(state, {
                    mediaInfo: {
                        [action.id]: {
                            $set: {
                                id: action.id,
                                version: action.version,
                                creation: defaultOperationState,
                                deletion: defaultOperationState,
                                author: action.author,
                                type: MediaType.Photo,
                                thumbnailUri: action.thumbnailUri,
                                uri: action.uri,
                                timestamp: action.timestamp,
                                timestamp_added: action.timestamp_added,
                                caption: createValue(action.caption),
                                starred: createValue(action.starred),
                                starCount: action.starCount,
                                geo: action.geo,
                                dimensions: action.dimensions,
                            },
                        },
                    },
                    mediaLink: {
                        [action.id]: {
                            $set: {
                                id: action.id,
                                section: createValue(action.section),
                            },
                        },
                    },
                    mediaImport: {
                        [action.id]: {
                            $set: {
                                id: action.id,
                                pending: false,
                                quality: MediaQuality.Preview, // nullable instead?
                                stages: [],
                            },
                        },
                    },
                });
            }
        }
        case REMOVE_MEDIA: {
            return update(state, {
                mediaImport: { $unset: [action.id] },
                mediaInfo: { $unset: [action.id] },
                mediaLink: { $unset: [action.id] },
            });
        }
        case CHANGE_STARRED_MEDIA: {
            const { clocks } = state;
            if (action.id in state.mediaInfo) {
                return update(state, {
                    mediaInfo: {
                        [action.id]: {
                            starred: starred =>
                                pushValue(starred, action.value, clocks),
                        },
                    },
                });
            } else {
                return state;
            }
        }
        case SET_MEDIA_CAPTION: {
            const { clocks } = state;
            if (!(action.id in state.mediaInfo)) return state;
            return update(state, {
                mediaInfo: {
                    [action.id]: {
                        caption: caption =>
                            pushValue(caption, action.caption, clocks),
                    },
                },
            });
        }
        case MOVE_MEDIA: {
            const { clocks } = state;
            if (action.id in state.mediaLink) {
                return update(state, {
                    mediaLink: {
                        [action.id]: {
                            section: section =>
                                pushValue(section, action.section, clocks),
                        },
                    },
                });
            } else {
                return state;
            }
        }
        case CREATE_SECTION: {
            // FIXME: Check that we got UUID
            if (action.id in state.sections) {
                // Already exists
                return state;
            }
            if (!(action.journal in state.journals)) {
                // Unknown journal
                return state;
            }
            return update(state, {
                sections: {
                    [action.id]: {
                        $set: {
                            id: action.id,
                            version: null,
                            creation: { pending: true, error: null },
                            deletion: defaultOperationState,
                            journal: action.journal,
                            title: createValue(action.title),
                        },
                    },
                },
            });
        }
        case DELETE_SECTION: {
            // FIXME: Check that we got UUID
            if (action.id in state.sections) {
                if (state.sections[action.id].creation.pending) {
                    return reducer(state, removeSection(action.id));
                } else {
                    return update(state, {
                        sections: {
                            [action.id]: {
                                deletion: {
                                    pending: { $set: true },
                                },
                            },
                        },
                    });
                }
            } else {
                return state;
            }
        }
        case CANCEL_DELETE_SECTION: {
            // FIXME: Check that we got UUID
            if (action.id in state.sections) {
                return update(state, {
                    sections: {
                        [action.id]: {
                            deletion: {
                                pending: { $set: false },
                            },
                        },
                    },
                });
            } else {
                return state;
            }
        }
        case DELETE_MEDIA: {
            // FIXME: Check that we got UUID
            if (action.id in state.mediaInfo) {
                if (state.mediaInfo[action.id].creation.pending) {
                    return reducer(state, removeMedia(action.id)); // FIXME: Test that.
                } else {
                    return update(state, {
                        mediaInfo: {
                            [action.id]: {
                                deletion: {
                                    pending: { $set: true },
                                },
                            },
                        },
                    });
                }
            } else {
                return state;
            }
        }
        case CREATE_MEDIA: {
            // FIXME: Check that we got UUID
            if (action.id in state.mediaInfo) {
                // Already exists
                // FIXME: Assert?
                return state;
            }
            if (!(action.section in state.sections)) {
                // Unknown section
                // FIXME: Log it? Assert?
                return state;
            }
            return update(state, {
                mediaInfo: {
                    [action.id]: {
                        $set: {
                            id: action.id,
                            version: null,
                            creation: { pending: true, error: null },
                            deletion: defaultOperationState,
                            author: null,
                            type: MediaType.Photo,
                            uri: null,
                            thumbnailUri: null,
                            timestamp: action.timestamp,
                            timestamp_added: null,
                            caption: createValue(action.caption),
                            starred: createValue(false),
                            starCount: 0,
                            geo: action.geo,
                            dimensions: action.dimensions,
                        },
                    },
                },
                mediaLink: {
                    [action.id]: {
                        $set: {
                            id: action.id,
                            section: createValue(action.section),
                        },
                    },
                },
                mediaImport: {
                    [action.id]: {
                        $set: {
                            id: action.id,
                            pending: true,
                            quality: MediaQuality.Large,
                            stages: [
                                {
                                    id: uuid4(),
                                    state: ImportState.Waiting,
                                    uri: action.uri,
                                    progress: 0,
                                    quality: MediaQuality.Preview,
                                },
                                {
                                    id: uuid4(),
                                    state: ImportState.Waiting,
                                    uri: action.uri,
                                    progress: 0,
                                    quality: action.quality,
                                },
                            ],
                        },
                    },
                },
            });
        }
        case REMOVE_IMPORT_STAGE: {
            if (!(action.id in state.mediaImport)) {
                // FIXME: Log? Assert?
                return state;
            }
            const index = state.mediaImport[action.id].stages.findIndex(
                item => item.id === action.stageId,
            );
            if (index === -1) {
                // FIXME: Log? Assert?
                return state;
            }
            return update(state, {
                mediaImport: {
                    [action.id]: {
                        stages: { $splice: [[index, 1]] },
                    },
                },
            });
        }
        case UPDATE_IMPORT_PROGRESS: {
            if (!(action.id in state.mediaImport)) {
                // FIXME: Log? Assert?
                return state;
            }
            const index = state.mediaImport[action.id].stages.findIndex(
                item => item.id === action.stageId,
            );
            if (index === -1) {
                // FIXME: Log? Assert?
                return state;
            }
            return update(state, {
                mediaImport: {
                    [action.id]: {
                        stages: {
                            [index]: {
                                progress: { $set: action.progress },
                            },
                        },
                    },
                },
            });
        }
        case SET_IMPORT_STAGE_STATE: {
            if (!(action.id in state.mediaImport)) {
                // FIXME: Log? Assert?
                return state;
            }
            const index = state.mediaImport[action.id].stages.findIndex(
                item => item.id === action.stageId,
            );
            if (index === -1) {
                // FIXME: Log? Assert?
                return state;
            }
            return update(state, {
                mediaImport: {
                    [action.id]: {
                        stages: {
                            [index]: {
                                state: { $set: action.state },
                                progress: { $set: 0 },
                            },
                        },
                    },
                },
            });
        }
        case RETRY_IMPORT: {
            if (!(action.id in state.mediaImport)) {
                // FIXME: Log? Assert?
                return state;
            }
            const stages = state.mediaImport[action.id].stages;
            if (stages.length === 0) {
                // FIXME: Log? Assert?
                return state;
            }
            return update(state, {
                mediaImport: {
                    [action.id]: {
                        stages: {
                            0: {
                                state: { $set: ImportState.Waiting },
                            },
                        },
                    },
                },
            });
        }
        default:
            return state;
    }
};
