import { Dispatch } from 'redux';
import { batch } from 'react-redux';

import {
    reset,
    setClocks,
    setVersion,
    declareJournal,
    declareSection,
    removeSection,
    declareMedia,
    removeMedia,
} from '@/common/actions';
import { extractChanges } from '@/common/changes';
import { State, RequestId, Server } from '@/common/types';
import { Command } from '@/common/commands';
import { sync } from '@/common/calls';
import { ApiError } from '@/common/error';

// const parseMessage = (data: string): Message => {
//     // We assume that the server message is valid
//     return JSON.parse(data) as Message;
// };

/** Apply the commands received from the server */
// FIXME: Error should cancel any change (such as unknown-command), to
// prevent an infinite loop to the server!
export const applyCommands = (
    version: number,
    changes: Array<Command>,
    errors: null | Record<RequestId, string>,
    dispatch: Dispatch,
) => {
    batch(() => {
        if (errors != null) {
            for (const [requestId, error] of Object.entries(errors)) {
                switch (error) {
                    case 'unknown-section': {
                        const m = requestId.match(/^delete-section:(.*)$/);
                        if (m !== null) {
                            dispatch(removeSection(m[1]));
                        }
                        break;
                    }
                }
            }
        }
        for (const change of changes) {
            switch (change.command) {
                case 'DECLARE-JOURNAL':
                    dispatch(
                        declareJournal(
                            change.id,
                            change.version,
                            change.title,
                            change.subtitle,
                        ),
                    );
                    break;
                case 'DECLARE-SECTION':
                    dispatch(
                        declareSection(
                            change.id,
                            change.version,
                            change.journal,
                            change.title,
                        ),
                    );
                    break;
                case 'REMOVE-SECTION':
                    dispatch(removeSection(change.id));
                    break;
                case 'DECLARE-MEDIA':
                    dispatch(
                        declareMedia(
                            change.id,
                            change.version,
                            change.author,
                            change.section,
                            change.caption,
                            change.starred,
                            change.starCount,
                            change.type,
                            change.uri,
                            change.thumbnailUri,
                            change.timestamp,
                            change.timestamp_added,
                            change.geo,
                            change.dimensions,
                        ),
                    );
                    break;
                case 'REMOVE-MEDIA':
                    dispatch(removeMedia(change.id));
                    break;
                // default:
                //     console.log('Unexpected commande:', change.command);
            }
        }
        dispatch(setVersion(version));
    });
};

export const update = async (
    server: Server,
    state: State,
    sendChanges: boolean,
    dispatch: Dispatch,
): Promise<boolean> => {
    const changes = sendChanges ? extractChanges(state) : [];
    const clocks = state.clocks;
    let success = false;
    if (!sendChanges || changes.length) {
        /** Advance the clock, since we are about to send the current
         * events to the server. After that, we will wait for new
         * events. */
        const nextClock = clocks.pending + 1;
        dispatch(
            setClocks({
                pending: nextClock,
            }),
        );
        try {
            const result = await sync(
                server,
                state.model,
                state.version,
                changes,
            );
            success = true;
            applyCommands(
                result.version,
                result.changes,
                result.errors,
                dispatch,
            );
            /** Store in the state that we are synchronized with the
             * server up to `nextClock`. */
            dispatch(
                setClocks({
                    sync: nextClock,
                }),
            );
        } catch (e) {
            if (e instanceof ApiError && e.code === 'INCOMPATIBLE-MODEL') {
                console.log('Resetting because of model change');
                dispatch(reset((e.data as any).model));
            } else {
                throw e;
            }
        }
    }
    return success;
};
