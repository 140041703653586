export type Clocks = {
    sync: number;
    pending: number;
};

type TimedValue<T> = {
    clock: number;
    value: T;
};

export type Value<T> = {
    value: T;
    buffer: null | TimedValue<T>;
    next: null | TimedValue<T>;
};

const create = <T>(value: T): Value<T> => ({
    value,
    buffer: null,
    next: null,
});

const simplify = <T>(value: Value<T>, clocks: Clocks): Value<T> => {
    let { value: known, buffer, next } = value;
    if (buffer !== null) {
        if (
            buffer.clock < clocks.sync ||
            buffer.value === known ||
            (next !== null && buffer.clock === next.clock)
        ) {
            buffer = null;
        } else {
            known = buffer.value;
        }
    }
    if (next !== null) {
        if (next.clock < clocks.sync || next.value === known) {
            next = null;
        }
    }
    if (buffer === null) {
        [buffer, next] = [next, null];
    }
    return { value: value.value, buffer, next };
};

// Not that useful, it seems.
// const getSync = <T,>(value: Value<T>, clocks: Clocks): [boolean, T] => {
//     value = simplify(value, clocks);
//     if (value.buffer !== null) {
//         return [true, value.buffer.value];
//     } else {
//         return [false, value.value];
//     }
// };

const getNext = <T>(value: Value<T>, clocks: Clocks): [boolean, T] => {
    value = simplify(value, clocks);
    if (value.next !== null) {
        return [true, value.next.value];
    } else if (value.buffer !== null) {
        return [true, value.buffer.value];
    } else {
        return [false, value.value];
    }
};

const getView = <T>(value: Value<T>, clocks: Clocks): [boolean, T] => {
    const [_changed, next] = getNext(value, clocks);
    return [next !== value.value, next];
};

const set = <T>(value: Value<T>, newValue: T, clocks: Clocks): Value<T> => {
    return simplify(
        {
            value: newValue,
            buffer: value.buffer,
            next: value.next,
        },
        clocks,
    );
};

const push = <T>(value: Value<T>, newValue: T, clocks: Clocks): Value<T> => {
    return simplify(
        {
            value: value.value,
            buffer: value.buffer,
            next: { clock: clocks.pending, value: newValue },
        },
        clocks,
    );
};

export { create, getNext, getView, set, push };
